import { inject } from '@angular/core';
import { AllIndexedDbStores } from '@models/indexed-db-stores.model';
import { OfflineId } from '@models/offline.model';
import { IndexedDBService } from '../indexed-db.service';

interface Identifiable {
  id: OfflineId;
}

export abstract class CoreIndexedDbService {
  protected readonly indexedDBService = inject(IndexedDBService);
  abstract readonly storeName: AllIndexedDbStores;

  protected async getDBConnection(): Promise<IDBDatabase> {
    return await this.indexedDBService.getDB();
  }

  // CRUD abstract methods
  protected async addRecord<T extends Identifiable>(
    storeName: string,
    record: T,
  ): Promise<number> {
    return this.indexedDBService.retryOperationDB(async () => {
      const db = await this.getDBConnection();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const key = record.id;
        const request = store.put(record, key);

        request.onsuccess = (): void => {
          resolve(request.result as number);
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    });
  }

  protected getRecord<T>(
    storeName: string,
    indexedDbId: OfflineId,
  ): Promise<T | undefined> {
    return this.indexedDBService.retryOperationDB(async () => {
      const db = await this.getDBConnection();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.get(indexedDbId);

        request.onsuccess = (): void => {
          resolve(request.result as T);
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    });
  }

  protected async updateRecord<T>(
    storeName: string,
    indexedDbId: OfflineId,
    updatedRecord: Partial<T>,
  ): Promise<void> {
    return this.indexedDBService.retryOperationDB(async () => {
      const db = await this.getDBConnection();
      const transaction = db.transaction(storeName, 'readwrite');
      const store = transaction.objectStore(storeName);

      const originalRecord = await new Promise<T>((resolve, reject) => {
        const getRequest = store.get(indexedDbId);

        getRequest.onsuccess = (): void => {
          resolve(getRequest.result as T);
        };

        getRequest.onerror = (): void => {
          reject(getRequest.error);
        };
      });

      const mergedRecord = { ...originalRecord, ...updatedRecord };

      return new Promise((resolve, reject) => {
        const putRequest = store.put({ ...mergedRecord, indexedDbId });

        putRequest.onsuccess = (): void => {
          resolve();
        };

        putRequest.onerror = (): void => {
          reject(putRequest.error);
        };
      });
    });
  }

  protected deleteRecord(
    storeName: string,
    indexedDbId: OfflineId,
  ): Promise<void> {
    return this.indexedDBService.retryOperationDB(async () => {
      const db = await this.getDBConnection();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.delete(indexedDbId);

        request.onsuccess = (): void => {
          resolve();
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    });
  }

  protected getAllRecords<T>(storeName: string): Promise<T[]> {
    return this.indexedDBService.retryOperationDB(async () => {
      const db = await this.getDBConnection();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readonly');
        const store = transaction.objectStore(storeName);
        const request = store.getAll();

        request.onsuccess = (): void => {
          resolve(request.result as T[]);
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    });
  }

  protected deleteAllRecords(storeName: string): Promise<void> {
    return this.indexedDBService.retryOperationDB(async () => {
      const db = await this.getDBConnection();
      return new Promise((resolve, reject) => {
        const transaction = db.transaction(storeName, 'readwrite');
        const store = transaction.objectStore(storeName);
        const request = store.clear();

        request.onsuccess = (): void => {
          resolve();
        };

        request.onerror = (): void => {
          reject(request.error);
        };
      });
    });
  }
}
